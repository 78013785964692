.boxCheck {
    input[type="checkbox"] {
        visibility: hidden;
    }

    input[type="checkbox"] + label:before {
        background-color: #fff;
        content: "\00a0";
        border-radius: 8px;
        display: inline-block;
        font: 16px/1em sans-serif;
        height: 22px;
        margin: 0 .25em 0 0;
        padding: 0;
        vertical-align: top;
        width: 25px;
    }

    input[type="checkbox"]:checked + label:before {
        background: #fff;
        color: #006B3F;
        content: "\2713";
        text-align: center;
        font-size: 22px;
        font-weight: bold;
    }
}

.filtroIcon {
    width: 2%;
    margin: 0 1.3% 0 1.3%;
}

.submit {
    width: 7.4%;
    margin-right: 6%;
}

.submitAfterForm {
    margin-right: 4%;
    margin-top: -40px;
}

.espacamentoImg {
    margin: 0 3% 0 5%;
}

.espacamentoImgForm {
    margin: 0 2% 0 2%;
}

.filtroCampos {
    -webkit-animation: scale-up-top .4s cubic-bezier(.39,.575,.565,1.000) both;
    animation: scale-up-top .4s cubic-bezier(.39,.575,.565,1.000) both
}

.submit {
    button {
        cursor: pointer;
    }
}

article.tamanhoForm {
    width: 59.6% !important;
}