
$setesois_1: #FF8B22;
$setesois_2: #FFB719;
$setesois_3: #FFF028;
$cor_n_Xxclaro: #fff;

$setesois-classes: setesois_1, setesois_2, setesois_3;
$setesois-classes-BC: setesois_1, setesois_2, setesois_3, n_Xxclaro;

$classSetesois: '';
$classStS: '';

@function selectCor( $var) {
    @if $var==setesois_1 {
        @return $setesois_1;
    }

    @else if $var==setesois_2 {
        @return $setesois_2;
    }

    @else if $var==setesois_3 {
        @return $setesois_3;
    }
    @else if $var==n_Xxclaro {
        @return $cor_n_Xxclaro;
    }
}

@mixin setesois-background($color) {
    @if $color == 'setesois_1' {
        background-color: #{$setesois_1};
    }
    @else if $color == 'setesois_2' {
        background-color: #{$setesois_2};
    }
    @else if $color == 'setesois_3' {
        background-color: #{$setesois_3};
    }
    @else {
        background-color: black;
    }
}
                                                                      
@mixin setesois-color($color) {
    @if $color == 'setesois_1' {
        color: #{$setesois_1};
    }
    @else if $color == 'setesois_2' {
        color: #{$setesois_2};
    }
    @else if $color == 'setesois_3' {
        color: #{$setesois_3};
    }
    @else {
        color: black;
    }
}

@each $classSetesois in $setesois-classes {
    .setesois-color.#{$classSetesois} {
        @include setesois-color('#{$classSetesois}');
    }

    .setesois-background.#{$classSetesois} {
        @include setesois-background('#{$classSetesois}');
    }
}

@each $classStS in $setesois-classes-BC {
    .color-#{$classStS} {
        color: selectCor( $classStS) !important;
    }

    .background-#{$classStS} {
        background-color: selectCor( $classStS) !important;
    }

    .hover-background-#{$classStS} {
        &:hover {
            background-color: selectCor( $classStS) !important;
        }
    }

    .hover-color-#{$classStS} {
        &:hover {
            color: selectCor( $classStS) !important;
        }
    }
}


.grafismoVitrine-setesois {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 50%);

    @include medium-large {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='559' height='533' viewBox='0 0 559 533' fill='none'%3E%3Cpath d='M66.9986 532.649H3.38023e-05C3.38023e-05 532.649 0.12052 427.394 2.87054e-05 416.411L3.29205e-06 38.446L0 0.789368L522.952 0.789322C529.941 -0.0554662 546.231 2.52723 555.484 19.6162C564.738 39.3119 553.557 56.0628 546.809 61.9764L81.5 527.649C76.584 532.573 75.6739 532.649 72.0592 532.649H66.9986Z' fill='%23FF8B22'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        height: 580px;
        position: absolute !important;
        top: 33% !important;
    }

    @include extra-large {
        top: 35%;
    }

    @include full-screem {
        top: 51%;
    }
}

.tour.setesois-tour {                                                     
    height: 912px;

    @include medium-large {
        height: auto;
    }

    .conteudografismoTour {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='559' height='533' viewBox='0 0 559 533' fill='none'%3E%3Cpath d='M66.9986 532.649H3.38023e-05C3.38023e-05 532.649 0.12052 427.394 2.87054e-05 416.411L3.29205e-06 38.446L0 0.789368L522.952 0.789322C529.941 -0.0554662 546.231 2.52723 555.484 19.6162C564.738 39.3119 553.557 56.0628 546.809 61.9764L81.5 527.649C76.584 532.573 75.6739 532.649 72.0592 532.649H66.9986Z' fill='%23FF8B22'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        height: 816px;

        @include medium-large {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='559' height='533' viewBox='0 0 559 533' fill='none'%3E%3Cpath d='M66.9986 532.649H3.38023e-05C3.38023e-05 532.649 0.12052 427.394 2.87054e-05 416.411L3.29205e-06 38.446L0 0.789368L522.952 0.789322C529.941 -0.0554662 546.231 2.52723 555.484 19.6162C564.738 39.3119 553.557 56.0628 546.809 61.9764L81.5 527.649C76.584 532.573 75.6739 532.649 72.0592 532.649H66.9986Z' fill='%23FF8B22'/%3E%3C/svg%3E");
            height: 600px;
            width: 110%;
        }

        article {
            padding: 332px 0 0;

            @include medium-large {
                padding: 56px 0 0 72px;
                width: 520px;
            }

            .btn {
                font-size: 12px;
            }

            .btn.condominio {
                margin-top: 62px;

                @include medium-large {
                    margin-top: 85px;
                }
            }

            h2 {
                @include medium-large {
                    font-size: 27px;
                }

                @include extra-large {
                    font-size: 2rem;
                }
            }

            p {
                @include medium-large {
                    font-size: 12px;
                }

                @include extra-large {
                    font-size: 1rem;
                }
            }
        }
    }
}

.setesois .tabs input[type="radio"]:checked + label,
.setesois .tabs2 input[type="radio"]:checked + label {
    //background-color: $cor_primaria_class_2 !important;
    background-color: $setesois_2 !important;
    color: #fff !important;
                                                
    &:hover {
        color: #fff;
        background-color: $setesois_2 !important;
    }
}

.setesois .slider-nav-lazer-apto {

    .slick-next {
        &:before {
            color: $setesois_2;
        }
    }

    .slick-prev {
        &:before {
            color: $setesois_2;
        }
    }
}

.setesois {
    .buttons {
        i {
            color: $setesois_2!important;              
        }
    }
}
@media(min-width: 768px) {

    .setesois.bg-galeria {
        background-color: $setesois_1 !important;
        background-image: url("../../assets/img/detalheDoImovel/linhaClass/galeria/vitrine-galeria-class.png");
        background-repeat: no-repeat;
        background-position-y: bottom;
        height: 680px;
    }

    .setesois.localizacao-area-mapa {
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        margin: auto;
        background: none !important;
    }

    .setesois.localizacao-area-mapa img {
        height: 500px !important;
    }

    .setesois.bg-mapa-area {
        background-color: $setesois_1 !important;
        background-image: url("../../assets/img/detalheDoImovel/linhaClass/mapa/vitrine-localizacao.png");
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: right;
    }
}



.setesois.planta .slider .slick-prev:before,
.setesois.planta .slider .slick-next:before {
    color: $setesois_2 !important;
    background: none;
}
