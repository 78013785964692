@import "geral";
@import "helpers/_medias.scss";
@import "util/_busca.scss";
@import "util/_cards.scss";

.icon-Whatsapp {
    &::before {
        margin-right: 8px;
    }
}

a.icon-Chat-1.display-none.display-md-block {
    &::before {
        margin-right: 12px;
    }
}

.bairro {
    display: none;

    @include medium-large {
        display: block;
    }
}

.sliderVitrineImoveis {
    .slick-prev {
        top: 175px;
        left: 10px;
        font-size: 20px;
        transform: unset;

        &:before {
            content: "\E814";
            @include fonte-icone-new;
        }
    }

    @include medium-large {
        .slick-prev {
            top: 160px;
        }
    }

    .slick-next {
        font-size: 20px;
        top: 175px;
        left: unset;
        margin: 0;
        right: -30px;

        &:before {
            content: "\E852";
            @include fonte-icone-new;
        }
    }

    @include medium-large {
        .slick-next {
            top: 160px;
        }
    }
}

@media(min-width: 992px) {
    .margin-ajuste {
        margin-top: -16rem !important;
    }
}

.tem-um-ape-pra-voce {
    background-image: url('https://cdn.mrv.com.br/imoveis/portal/assets/img/home/background-tem-um-ape-pra-voce.png');
    height: 690px;
    min-height: 690px;
}

@media(min-width: 1400px) {
    .tem-um-ape-pra-voce {
        background-size: 100%;
    }
}

.logo-ape-mrv {
    margin-top: 210px;
}